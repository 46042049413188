<template>
  <div v-if="isMobile" class="container" ref="scrollWrap">
    <div class="swiper">
      <swiper :options="swiperDetailOptions" v-if="bannerList.length">
        <swiper-slide v-for="item in bannerList" :key="item.id">
          <div class="swiper-item">
            <a
              v-if="item.jump_url"
              :link="item.jump_url"
              class="mr5"
              target="_blank"
            >
              <img :src="item.img" alt="" />
            </a>
            <router-link
              v-else
              :to="detailLink + '/' + item.id"
              class="mr5"
              target="_blank"
            >
              <img :src="item.img" alt="" />
            </router-link>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="newstab-container">
      <div class="newstab-title-wrap">
        <div class="newstab-title">
          <div
            v-for="(item, key) in titleList"
            :key="key"
            @click="changeTab(key)"
            class="title"
            :class="{ activeTitle: activeKey === key }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="introduction-wrap" v-if="isShowIntroduction">
          <div @click="changeTab(4)">
            <img src="../../assets/img/new/novice.png" alt="" />
          </div>
          <div @click="changeTab(5)">
            <img src="../../assets/img/new/Introduction.png" alt="" />
          </div>
        </div>
      </div>

      <div class="newstab-content-wrap">
        <div
          v-for="(item, key) in contentList"
          :key="key"
          class="newstab-content"
          :class="{ activeContent: activeKey === key }"
        >
          <div
            v-for="(content, contentKey) in item.data.list || item.data"
            :key="contentKey"
          >
            <a
              v-if="content.jump_url"
              class="newstab-content-detail"
              target="_blank"
              :href="content.jump_url"
            >
              <img :src="content.img" alt="" />
              <div>
                <div class="content-title" v-if="titleList[key]">
                  <div class="icon">
                    <span></span>
                  </div>
                  <div class="icon-next">
                    【
                    <div class="content-title-detail">
                      {{ setContentName(content.mid) }}
                    </div>
                    】
                  </div>
                </div>
                <div class="content-detail">{{ content.title }}</div>
                <div class="content-time">
                  {{ timeFormat(content.tm_publish_s) }}
                </div>
              </div>
            </a>
            <router-link
              v-else
              class="newstab-content-detail"
              :to="detailLink + '/' + content.id"
              target="_blank"
            >
              <img :src="content.img" alt="" />
              <div class="m-content">
                <div class="icon-wrap">
                  <div class="icon">
                    <span></span>
                  </div>
                </div>
                <div class="content-title">
                  <div class="content" v-if="titleList[key]">
                    <span>【</span>
                    <span class="content-title-detail">{{
                      setContentName(content.mid)
                    }}</span>
                    <span>】</span>
                    <span class="content-detail">{{ content.title }}</span>
                  </div>

                  <div class="content-time">
                    {{ timeFormat(content.tm_publish_s) }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="mobileLoading" class="newsloading">
          <img src="../../assets/img/loading.gif" alt="" />
        </div>
        <div v-if="notList" class="notList">もうデータがない</div>
      </div>
    </div>
  </div>
  <div v-else class="container">
    <div class="banner" v-if="bannerList.length === 2">
      <div v-for="item in bannerList" :key="item.id">
        <a
          v-if="item.jump_url"
          :link="item.jump_url"
          class="mr5"
          target="_blank"
        >
          <img :src="item.img" alt="" />
        </a>
        <router-link
          v-else
          :to="detailLink + '/' + item.id"
          class="mr5"
          target="_blank"
        >
          <img :src="item.img" alt="" />
        </router-link>
      </div>
    </div>

    <div class="newstab-container">
      <div class="newstab-title-wrap">
        <div class="newstab-title">
          <div
            v-for="(item, key) in titleList"
            :key="key"
            @click="changeTab(key, item.mid)"
            class="title"
            :class="{ activeTitle: activeKey === key }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="newstab-content-wrap" v-if="titleList">
        <div
          v-for="(item, key) in contentList"
          :key="key"
          class="newstab-content"
          :class="{ activeContent: activeKey === key }"
        >
          <div
            v-for="(content, contentKey) in item.data.list || item.data"
            :key="contentKey"
          >
            <a
              v-if="content.jump_url"
              class="newstab-content-detail"
              target="_blank"
              :href="content.jump_url"
            >
              <img :src="content.img" alt="" />
              <div>
                <div class="content-title" v-if="titleList[key]">
                  <div class="icon">
                    <span></span>
                  </div>
                  <div class="icon-next">
                    【
                    <div class="content-title-detail">
                      {{ setContentName(content.mid) }}
                    </div>
                    】
                  </div>
                </div>
                <div class="content-detail">{{ content.title }}</div>
                <div class="content-time">
                  {{ timeFormat(content.tm_publish_s) }}
                </div>
              </div>
            </a>
            <router-link
              v-else
              class="newstab-content-detail"
              :to="detailLink + '/' + content.id"
              target="_blank"
            >
              <img :src="content.img" alt="" />
              <div>
                <div class="content-title" v-if="titleList[key]">
                  <div class="icon">
                    <span></span>
                  </div>
                  <div class="icon-next">
                    【
                    <div class="content-title-detail">
                      <!-- {{ titleList[key].name }} -->
                      <!-- {{titleList[parseInt(content.mid)].name}} -->
                      {{ setContentName(content.mid) }}
                    </div>
                    】
                  </div>
                </div>
                <div class="content-detail">{{ content.title }}</div>
                <div class="content-time">
                  {{ timeFormat(content.tm_publish_s) }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- ||||||||||||||||||||||||||| -->
    <!-- v-show="contentList[activeKey].data.list.length > 0" -->
    <div
      class="pagination"
      v-show="contentList[activeKey].data.list.length > 0"
    >
      <div
        class="first"
        :class="{ firstDisable: pageActive === 1 }"
        @click="jumpPages(1)"
      ></div>
      <div
        class="front"
        :class="{ frontDisable: pageActive === 1 }"
        @click="jumpPages(2)"
      ></div>
      <div
        class="pagenumber"
        v-for="item in pageNumberList"
        :key="item"
        :class="{ activePageNumber: pageActive === item }"
        @click="turnPages(item)"
      >
        <div>
          <span>
            {{ item }}
          </span>
        </div>
      </div>
      <div
        class="after"
        :class="{ afterDisable: pageActive === pagenumberTotal }"
        @click="jumpPages(3)"
      ></div>
      <div
        class="last"
        :class="{ lastDisable: pageActive === pagenumberTotal }"
        @click="jumpPages(4)"
      ></div>
    </div>
    <!-- ||||||||||||||||||||||||||| -->

    <!-- ||||||||||||||||||||||||||| -->
    <!-- <div
      class="pagination"
      v-show="contentList[activeKey].data.list.length > 0"
    >
      <div
        class="first"
        :class="{ firstDisable: pageActive === 1 }"
        @click="jumpPages(1)"
      ></div>
      <div
        class="front"
        :class="{ frontDisable: pageActive === 1 }"
        @click="jumpPages(2)"
      ></div>
      <el-pagination
        layout="pager"
        class="newsPager"
        @current-change="handleCurrentChange"
        :current-page="pageActive"
        :total="pagenumberTotal * 10"
      >
      </el-pagination>
      <div
        class="after"
        :class="{ afterDisable: pageActive === pagenumberTotal }"
        @click="jumpPages(3)"
      ></div>
      <div
        class="last"
        :class="{ lastDisable: pageActive === pagenumberTotal }"
        @click="jumpPages(4)"
      ></div>
    </div> -->
    <!-- ||||||||||||||||||||||||||| -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { getArticleList, getChildList, getNewest } from "@/api/index";
import { timeFormat, isMobile } from "@/utils/util";

export default {
  name: "News",
  data() {
    return {
      isMobile,
      detailLink: "/NewsDetail",
      titleList: [],
      contentList: [],
      activeKey: 0,
      pageActive: 1,
      mobilePageNumber: 1,
      pagenumberTotal: 1,
      pageNumberList: [],
      optinsNum: 5,
      isShowIntroduction: false,
      isShowPagination: false,
      swiperDetailOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        preventClicks: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      bannerList: [],
      notList: false,
      mobileLoading: false,
      debounce: true,
    };
  },
  methods: {
    ...mapMutations(["increment"]),
    changeTab(key) {
      console.log(key);
      this.activeKey = key;
      this.pageActive = 1;
      this.turnPages(1);
    },
    timeFormat(time) {
      return timeFormat(time * 1000);
    },
    async getList(mid, page) {
      const data = await getArticleList({ mid, num: this.optinsNum, p: page });
      // .then((res) => {
      //   console.log(res);
      // });
      return data;
    },
    getIntroductionList(mid, p) {
      getNewest({ mid, num: this.optinsNum, p: p || 1 }).then((res) => {
        console.log(res);
      });
    },
    async turnPages(number) {
      this.pageActive = number;
      const activeKey = this.activeKey;
      const midList = this.midList;
      const mid = midList[activeKey];
      const data = await this.getList(mid, number);
      this.contentList.splice(activeKey, 1, data);
    },
    async jumpPages(number) {
      const activeKey = this.activeKey;
      const pageActive = this.pageActive;
      // const midList = ["1", "18", "3", "2"];
      const midList = this.midList;
      const mid = midList[activeKey];
      let data = {};
      switch (number) {
        case 1:
          if (pageActive > 1) {
            // this.getVideoList(this.actiSecondveKey, 1);
            data = await this.getList(mid, 1);
            this.pageActive = 1;
          }
          break;
        case 2:
          if (pageActive > 1) {
            // this.getVideoList(this.actiSecondveKey, pageActive - 1);
            data = await this.getList(mid, pageActive - 1);
            this.pageActive = pageActive - 1;
          }
          break;
        case 3:
          if (pageActive < this.pagenumberTotal) {
            // this.getVideoList(this.actiSecondveKey, pageActive + 1);
            data = await this.getList(mid, pageActive + 1);
            this.pageActive = pageActive + 1;
          }
          break;
        case 4:
          // this.getVideoList(this.actiSecondveKey, this.pagenumberTotal);
          if (pageActive < this.pagenumberTotal) {
            data = await this.getList(mid, this.pagenumberTotal);
            this.pageActive = this.pagenumberTotal;
          }
          break;
        default:
          break;
      }
      console.log(data);
      if (data.data.list) {
        this.contentList.splice(activeKey, 1, data);
      }
      // this.pageActive = number;
    },
    setContentName(mid) {
      const list = this.titleList;
      let name = "";
      if (mid === "20" || mid === "19") {
        name = "精品攻略";
      } else {
        list.map((item) => {
          if (item.mid === mid) {
            name = item.name;
          }
        });
      }
      return name;
    },
    setDefaultValue(result) {
      try {
        if (isMobile) {
          if(this.$route.path.indexOf("/Introduction") >= 0) {
            let list = [];
            for (let i = 0; i < result.length; i++) {
              if(result[i].data.list[0]) {
                list.push({id: result[i].data.list[0].id,img: result[i].data.list[0].img})
              }
            }
            this.bannerList = list;
          } else {
            let list = [];
            for (let i = 0; i < 5; i++) {
              list.push({id: result[0].data.list[i].id,img: result[0].data.list[i].img})
            }
            this.bannerList = list;
          }
        } else {
          const bannerList = [];
          if(result[0].data.list[0]) {
            bannerList.push({
              id: result[0].data.list[0].id,
              img: result[0].data.list[0].img,
            })
          }
          if(result[0].data.list[1]) {
            bannerList.push({
              id: result[0].data.list[1].id,
              img: result[0].data.list[1].img,
            })
          }
          this.bannerList = bannerList;
        }
        const data = result[0].data;
        // const total = data.totalPage;
        // data.totalPage = 11;
        this.pagenumberTotal = data.totalPage;
        const total = data.totalPage > 5 ? 5 : data.totalPage;
        const pageList = [];
        for (let i = 1; i <= total; i++) {
          pageList.push(i);
        }
        this.pageNumberList = pageList;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentChange(number) {
      this.turnPages(number);
    },
    getMoreMessage(activeKey) {
      // const total = this.pagenumberTotal;
      this.debounce = false;
      const p = this.mobilePageNumber;
      let list = [];
      if (this.$route.path.indexOf("/Introduction") >= 0) {
        list = [4, 23, 24, 25];
      } else {
        list = [1, 18, 3, 2];
      }
      this.mobileLoading = true;
      getArticleList({
        mid: list[activeKey],
        num: this.optinsNum,
        device_type: "mob",
        p: p + 1,
      })
        .then((res) => {
          this.mobilePageNumber += 1;
          this.mobileLoading = false;
          console.log(res);
          const list = this.contentList[activeKey].data.list.concat(
            res.data.list
          );
          // const list = this.contentList[activeKey].data.list.concat(testData.data.list);
          // this.$set(this.contentList[activeKey],  , list);
          // this.contentList = [list]
          let contentList = this.contentList;
          contentList[activeKey].data.list = list;
          this.contentList = contentList;
          console.log(contentList, list);
          this.debounce = true;
        })
        .catch((err) => {
          console.log(err);
          this.debounce = true;
        });
    },
    handleScroll() {
      var scrollTop = this.$refs.scrollWrap.scrollTop; //滚动条的位置
      var windowHeitht = this.$refs.scrollWrap.clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.$refs.scrollWrap.scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      //是否滚动到底部的判断
      var height = scrollHeight - Math.round(scrollTop) - windowHeitht;
      if (height === 0) {
        console.log(scrollTop, windowHeitht, scrollHeight);
        let activeKey = this.activeKey;
        // const totalPage = 11;
        // if (this.mobilePageNumber <= totalPage) {
        console.log(this.mobilePageNumber, this.pagenumberTotal);
        if (this.mobilePageNumber <= this.pagenumberTotal) {
          if (this.debounce) {
            this.getMoreMessage(activeKey);
          }
        } else {
          this.notList = true;
          return;
        }
      }
    },
  },
  mounted() {
    this.increment({ status: 1 });
    // console.log(this.$route.path);
    const { path, query } = this.$route;
    const optinsNum = isMobile ? 10 : 5;
    this.optinsNum = optinsNum;
    const device_type = this.isMobile ? "mob" : "web";
    console.log(this.$route);
    if (path.indexOf("/Introduction") >= 0) {
      this.detailLink = "/IntroductionDetail";
      getChildList({ mid: 4 }).then(({ data }) => {
        console.log(data);
        this.titleList = [
          { mid: "4", name: "攻略情報" },
          { mid: "23", name: "山海イベント" },
          { mid: "24", name: "山海システム" },
          { mid: "25", name: "山海アイテム" },
        ];
        this.midList = [4, 23, 24, 25];
        Promise.all([
          getArticleList({ mid: 4, num: optinsNum, device_type }),
          getArticleList({ mid: 23, num: optinsNum, device_type }),
          getArticleList({ mid: 24, num: optinsNum, device_type }),
          getArticleList({ mid: 25, num: optinsNum, device_type }),
        ])
          .then((result) => {
            // console.log("----------", result);
            this.contentList = result;
            this.setDefaultValue(result);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      if (query.activeKey) {
        this.activeKey = parseInt(query.activeKey);
      }

      getChildList({ mid: 1 }).then(({ data }) => {
        data.unshift({ mid: "1", name: "NEW" });
        // console.log(data);
        this.titleList = data;
        this.midList = [data[0].mid, data[1].mid, data[2].mid, data[3].mid];

        Promise.all([
          getArticleList({ mid: 1, num: optinsNum, device_type }),
          getArticleList({ mid: 18, num: optinsNum, device_type }),
          getArticleList({ mid: 3, num: optinsNum, device_type }),
          getArticleList({ mid: 2, num: optinsNum, device_type }),
        ])
          .then((result) => {
            // result[0].data.list = result[0].data.list.concat(testData.data.list);
            this.contentList = result;
            console.log(result);
            this.setDefaultValue(result);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
    if (isMobile) {
      this.$nextTick(() => {
        this.$refs.scrollWrap.addEventListener(
          "scroll",
          this.handleScroll,
          true
        );
      });
    }
  },
  watch: {
    activeKey(newValue) {
      // console.log(newValue);
      if (this.contentList[newValue]) {
        const data = this.contentList[newValue].data;
        console.log(data);
        this.pagenumberTotal = data.totalPage;
        const total = data.totalPage > 5 ? 5 : data.totalPage;
        const pageList = [];
        for (let i = 1; i <= total; i++) {
          pageList.push(i);
        }
        this.pageNumberList = pageList;
        this.mobilePageNumber = 1;
        this.notList = false;
        // if (data.totalPage > 1) {
        //   this.isShowPagination = true;
        //   this.pagenumberTotal = data.totalPage;
        // } else {
        //   this.isShowPagination = false;
        // }
      }
    },
    pageActive(value) {
      const total = this.pagenumberTotal;
      if (value > 3 && value <= total - 2) {
        const min = value - 2;
        const max = value + 2;
        const list = [];
        for (let i = min; i <= max; i++) {
          list.push(i);
        }
        this.pageNumberList = list;
      }
      if (value <= 3 && total > 5) {
        this.pageNumberList = [1, 2, 3, 4, 5];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .container {
    // min-height: calc(100% - 87px);
    height: 100vh;
    box-sizing: border-box;
    padding-top: 0.85rem;
    padding-bottom: 2rem;
    background: url(../../assets/img/app/bg.jpg) no-repeat;
    background-size: 100% 100%;
    background-position: center bottom;
    overflow: auto;
  }
  .audioVisual-title {
    display: flex;
    background-image: url(../../assets/img/app/newsTabBg.png);
    background-size: cover;
    width: 93vw;
    margin: 0 auto 0.56rem;
    .title {
      height: 0.9rem;
      width: 2.2rem;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
      // background-image: url(../../../assets/img/tabTitleBg.png);
    }
    .activeTitle {
      background: url(../../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }
  .newstab-title-wrap {
    display: flex;
    background-image: url(../../assets/img/app/newsTabBg.png);
    background-size: cover;
    width: 97vw;
    margin: 0.12rem auto;
    .introduction-wrap {
      display: flex;
      padding-right: 68px;
      img {
        height: 41px;
        margin-right: 27px;
      }
    }
  }

  .newstab-content-wrap {
    position: relative;
    width: 100%;
    .newstab-content {
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 20px 82px 0;
      box-sizing: border-box;
      display: none;
    }
    .activeContent {
      z-index: 9;
      display: block;
      // padding-bottom: 2rem;
    }
  }
  .newstab-title {
    display: flex;
    // justify-content: center;
    width: 100%;
    .title {
      height: 0.9rem;
      width: 25%;
      line-height: 0.9rem;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 0.28rem;
    }
    .activeTitle {
      background: url(../../assets/img/app/newsCheckBorder.png);
      background-size: cover;
      // width: 248px;
      position: relative;
    }
    .activeTitle::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.05rem;
      left: 0.06rem;
      width: 0.35rem;
      height: 0.4rem;
      background: url(../../assets/img/app/newsCheckIcon.png) no-repeat;
      background-size: contain;
    }
  }

  .newstab-content-detail {
    display: flex;
    height: 1.3rem;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
    background: url(../../assets/img/new/newsItemBg.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    img {
      height: 100%;
      width: 2.5rem;
      // width: 15%;
    }
    .content-title {
      // display: flex;
      color: #15385b;
      padding: 0 0.2rem;
      .content {
        font-size: 0.26rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 0.6rem;
        margin-bottom: 0.2rem;
        line-height: 0.3rem;
      }
      .icon-next {
        display: flex;
      }

      .content-time {
        font-size: 0.2rem;
        // flex: 1;
        // text-align: right;
        // margin-right: 30px;
      }
    }
    .content-detail {
      // flex: 1;
      // width: 666px;
      color: #15385b;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // word-break: break-all;
    }
  }

  .m-content {
    height: 100%;
    box-sizing: border-box;
    padding: 0.2rem 0 0 0.1rem;
    // align-items: center;
    .icon-wrap {
      width: 0.3rem;
    }

    .icon {
      width: 0.2rem;
      height: 0.2rem;
      border: 1px solid #15385b;
      transform: rotate(45deg);
      // margin-left: .1rem;
      // margin-right: .1rem;
      // background: #15385b;
      padding: 0.02rem;
      box-sizing: border-box;
      margin-left: 0.1rem;
      // background-origin: content-box;
      span {
        display: block;
        width: 100%;
        height: 100%;
        // margin: .04rem;
        background: #15385b;
      }
    }
  }

  .swiper {
    width: 100vw;
    height: 3.5rem;
    img {
      display: block;
      width: 100vw;
      max-height: 3.5rem;
    }
  }
  .notList {
    font-size: 0.4rem;
    text-align: center;
    line-height: 1.5;
    color: #aaa;
  }
  .newsloading {
    img {
      height: 0.6rem;
      margin: 0 auto;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (min-width: 751px) {
  .swiper-container {
    height: 100%;
  }
  .container {
    min-height: calc(100% - 87px);
    background: url(../../assets/img/new/bg.png) no-repeat;
    background-size: cover;
    background-position: bottom;
    padding-top: 87px;
  }
  .newstab-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url(../../assets/img/titleWrap.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    .introduction-wrap {
      display: flex;
      padding-right: 68px;
      img {
        height: 41px;
        margin-right: 27px;
      }
    }
  }
  .newstab-title {
    display: flex;
    // justify-content: center;
    padding-left: 80px;
    .title {
      height: 84px;
      width: 200px;
      line-height: 84px;
      text-align: center;
      color: #15385b;
      cursor: pointer;
      font-size: 28px;
    }
    .activeTitle {
      background: url(../../assets/img/newsCheckBoeder.png);
      background-size: cover;
      width: 248px;
    }
  }

  .newstab-content-wrap {
    position: relative;
    width: 100%;
    .newstab-content {
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 20px 82px 0;
      box-sizing: border-box;
      display: none;
    }
    .activeContent {
      z-index: 9;
      display: block;
      height: 430px;
    }
  }

  .newstab-content-detail {
    display: flex;
    height: 70px;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
    background: url(../../assets/img/new/newsItemBg.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    img {
      height: 100%;
      // width: 15%;
    }
    .content-title {
      display: flex;
      color: #15385b;
      .icon {
        width: 18px;
        height: 18px;
        border: 1px solid #15385b;
        transform: rotate(45deg);
        margin-left: 18px;
        margin-right: 34px;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin: 4px;
          background: #15385b;
        }
      }
      .icon-next {
        display: flex;
      }
      .content-title-detail {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
    .content-detail {
      flex: 1;
      // width: 666px;
      color: #15385b;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      line-height: 1.2;
    }
    .content-time {
      // flex: 1;
      text-align: right;
      margin-right: 30px;
    }
  }
}
</style>

<style lang="scss" scoped>
.banner {
  display: flex;
  // width: 98%;
  margin: 0 auto;
  padding: 5px 0;
  justify-content: space-between;
  a {
    display: block;
    width: 49.6vw;
    height: 200px;
    overflow: hidden;
    img {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
    // border-radius: 8px;
  }
}
.newstab-container {
  position: relative;
}

.newstab-content-detail > div {
  display: flex;
  flex: 1;
}

.newstab-content-detail:hover .content-detail,
.newstab-content-detail:hover .content-title {
  color: #b79350;
}

.newstab-content-detail:hover .icon {
  border-color: #b79350;
  span {
    background: #b79350;
  }
}

.pagination {
  // height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 27px;
}
.pagination > div {
  margin-right: 7.5px;
  cursor: pointer;
}
.first,
.front,
.after,
.last {
  width: 45px;
  height: 45px;
  background-size: cover;
}
.first {
  background-image: url(../../assets/img/new/first.png);
}
.firstDisable {
  background-image: url(../../assets/img/new/firstDisable.png);
}
.front {
  background-image: url(../../assets/img/new/front.png);
}
.frontDisable {
  background-image: url(../../assets/img/new/frontDisable.png);
}
.after {
  background-image: url(../../assets/img/new/after.png);
}
.afterDisable {
  background-image: url(../../assets/img/new/afterDisable.png);
}
.last {
  background-image: url(../../assets/img/new/last.png);
}
.lastDisable {
  background-image: url(../../assets/img/new/lastDisable.png);
}
.pagenumber {
  width: 26px;
  height: 26px;
  padding: 2px;
  border: 1px solid #92cae1;
  transform: rotate(45deg);
  margin: 0 8px;
}
.pagenumber > div {
  height: 100%;
  background: #92cae1;
  color: #15385b;
}
.activePageNumber {
  border: 1px solid #15385b;
}
.activePageNumber > div {
  background: #15385b;
  color: #fff;
}
.pagenumber > div > span {
  display: inline-block;
  height: 100%;
  width: 100%;
  transform: rotate(-45deg);
  text-align: center;
  line-height: 23px;
}

.mr5 {
  margin-right: 5px;
}
</style>